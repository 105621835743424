import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Heading, Text, Stack, VStack, Divider, Grid, Badge, 
  Progress, Card, CardBody, SimpleGrid, useToast, Flex,
  Icon, Button, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input,
  useDisclosure, Spinner
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getForm, getSubmissionData, getQuestions, getSubmissionRef } from '../utils/firebase.utils';
import { useTranslation } from 'react-i18next';
import SkillsAnalysis from './components/SkillsAnalysis';
import { CheckIcon, CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import html2pdf from 'html2pdf.js';

const SubmissionDetails = ({ accountId, userEmail }) => {
  const { t } = useTranslation();
  const { submissionId, formId } = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submission, setSubmission] = useState(null);
  const [form, setForm] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [questionsHash, setQuestionsHash] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState({});
  const [email, setEmail] = useState(userEmail || '');
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchSubmissionDetails = async () => {
      try {
        setLoading(true);
        // Get the submission reference
        const submissionRef = await getSubmissionRef(accountId, submissionId);
        
        // Get the submission data
        const submissionData = await getSubmissionData(submissionRef, accountId, submissionId);
        setSubmission(submissionData);
        
        // Get the form data
        const formData = await getForm(accountId, submissionData.formId || formId);
        setForm(formData);
        
        // Get questions
        const questionsData = await getQuestions(accountId, submissionData.formId || formId);
        setQuestions(questionsData);
        
        // Create questions hash for score calculation
        const qHash = {};
        questionsData.forEach((question) => {
          qHash[question.id] = qHash[question.id] || {};
          Object.entries(question.options).forEach(([key, option]) => {
            if (option.value > 0) {
              qHash[question.id][key] = option.value;
            }
          });
        });
        setQuestionsHash(qHash);
        
        // Set responses from submission data
        const submissionResponses = {};
        submissionResponses[submissionId] = submissionData.responses || {};
        setResponses(submissionResponses);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching submission details:', err);
        setError(err.message);
        setLoading(false);
        toast({
          title: t('error'),
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (accountId && submissionId) {
      fetchSubmissionDetails();
    }
  }, [accountId, submissionId, formId, t, toast]);

  const handleGeneratePDF = async () => {
    try {
      setGeneratingPDF(true);
      
      // Crear una copia del contenido para modificarlo antes de generar el PDF
      const element = contentRef.current.cloneNode(true);
      
      // Obtener identificador de usuario (nombre o email)
      const userIdentifier = submission.userIdentifier || submission.email || 'unknown-user';
      
      // Limpiar el identificador para que sea válido como nombre de archivo
      // Elimina caracteres problemáticos (/, \, :, *, ?, ", <, >, |, etc.)
      const safeUserIdentifier = userIdentifier
        .replace(/[/\\:*?"<>|=+,;{}[\]]/g, '') // Eliminar caracteres ilegales en nombres de archivo
        .replace(/\s+/g, '_') // Reemplazar espacios con guiones bajos
        .substring(0, 30); // Limitar longitud
      
      // Obtener la fecha actual en formato simple (YYYYMMDD)
      const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
      
      // Crear nombre de archivo con título de evaluación, usuario y fecha
      const fileName = `${submission.title || 'assessment'}_${safeUserIdentifier}_${currentDate}.pdf`;
      
      // Configuración para PDF
      const options = {
        margin: [10, 10, 10, 10],
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      
      // Generar PDF
      await html2pdf().from(element).set(options).save();
      
      setGeneratingPDF(false);
      
      toast({
        title: t('pdf_generated', 'PDF Generado'),
        description: t('pdf_download_started', 'La descarga del PDF comenzará en breve'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.error('Error generating PDF:', err);
      setGeneratingPDF(false);
      
      toast({
        title: t('error'),
        description: t('export_error'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <Text>{t('loading')}</Text>;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  if (!submission) {
    return <Text>{t('submission_not_found')}</Text>;
  }

  const renderQuestionResponses = () => {
    return (
      <Stack spacing={6} width="100%">
        {questions.map((question) => {
          const response = submission.responses?.[question.id];
          const isCorrect = response && questionsHash[question.id]?.[response.id] > 0;
          const correctOption = Object.entries(question.options || {}).find(
            ([id, option]) => option.value > 0
          );
          
          // Determine border color and background based on response correctness
          const cardBorderColor = !response ? "gray.200" : (isCorrect ? "green.300" : "red.300");
          const cardBgColor = !response ? "white" : (isCorrect ? "green.50" : "red.50");
          
          return (
            <Card 
              key={question.id} 
              variant="outline" 
              width="100%" 
              borderColor={cardBorderColor}
              borderWidth="2px"
              bg={cardBgColor}
              boxShadow="md"
              transition="all 0.2s"
            >
              <CardBody>
                <VStack align="start" spacing={3}>
                  <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <Heading size="md">{question.name}</Heading>
                    {response && (
                      <Badge 
                        colorScheme={isCorrect ? "green" : "red"} 
                        fontSize="0.9em" 
                        p={2} 
                        borderRadius="full"
                      >
                        {isCorrect ? t('correct') : t('wrong')}
                      </Badge>
                    )}
                  </Flex>
                  
                  {question.questionImageURL && (
                    <Box>
                      <img 
                        src={question.questionImageURL} 
                        alt="Question" 
                        style={{ maxWidth: '300px' }} 
                      />
                    </Box>
                  )}
                  
                  <Divider />
                  
                  <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
                    <Box 
                      p={3} 
                      bg={!response ? "gray.100" : (isCorrect ? "green.100" : "red.100")} 
                      borderRadius="md"
                    >
                      <Flex alignItems="center" mb={2}>
                        <Text fontWeight="bold" mr={2}>{t('user_response')}:</Text>
                        {response && (
                          <Icon 
                            as={isCorrect ? CheckIcon : CloseIcon} 
                            color={isCorrect ? "green.500" : "red.500"} 
                            boxSize={5} 
                          />
                        )}
                      </Flex>
                      <Text 
                        fontWeight={response ? "medium" : "normal"} 
                        color={!response ? "gray.500" : "black"}
                      >
                        {response ? response.name : t('no_response')}
                      </Text>
                    </Box>
                    
                    <Box p={3} bg="green.100" borderRadius="md">
                      <Flex alignItems="center" mb={2}>
                        <Text fontWeight="bold" mr={2}>{t('correct_answer')}:</Text>
                        <Icon as={CheckIcon} color="green.500" boxSize={5} />
                      </Flex>
                      <Text fontWeight="medium">
                        {correctOption ? correctOption[1].name : t('no_correct_option')}
                      </Text>
                    </Box>
                  </Grid>
                </VStack>
              </CardBody>
            </Card>
          );
        })}
      </Stack>
    );
  };

  // Calculate overall performance indicators
  const correctCount = questions.reduce((count, question) => {
    const response = submission.responses?.[question.id];
    return count + (response && questionsHash[question.id]?.[response.id] > 0 ? 1 : 0);
  }, 0);
  
  const totalQuestions = questions.length;
  const percentCorrect = totalQuestions > 0 ? (correctCount / totalQuestions) * 100 : 0;

  return (
    <Box p={5}>
      <Flex justifyContent="space-between" alignItems="center" width="100%" mb={4}>
        <Heading>{submission.title || t('submission_details')}</Heading>
        <Button 
          size="sm" 
          colorScheme="blue" 
          leftIcon={generatingPDF ? <Spinner size="sm" /> : <DownloadIcon />} 
          onClick={handleGeneratePDF}
          isLoading={generatingPDF}
          loadingText={t('generating_pdf', 'Generando PDF...')}
        >
          {t('download_pdf', 'Descargar PDF')}
        </Button>
      </Flex>
      
      <VStack ref={contentRef} spacing={6} align="start" width="100%">
        <Box className="pdf-header" width="100%" mb={4} display="flex" justifyContent="space-between">
          <Heading as="h1" size="xl">{submission.title || t('submission_details')}</Heading>
          <Text>{new Date().toLocaleDateString()}</Text>
        </Box>

        <Card variant="outline" width="100%" boxShadow="md">
          <CardBody>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <Box>
                <Text fontWeight="bold">{t('user')}:</Text>
                <Text>{submission.userIdentifier || submission.email}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">{t('score')}:</Text>
                <Text>{submission.score || 0} / {form?.totalPoints || 0}</Text>
                <Progress 
                  value={(submission.score / (form?.totalPoints || 1)) * 100} 
                  colorScheme="green" 
                  size="sm" 
                  mt={2} 
                />
              </Box>
              <Box>
                <Text fontWeight="bold">{t('start_time')}:</Text>
                <Text>
                  {submission.startTime?.toDate?.().toLocaleString() || t('not_available')}
                </Text>
              </Box>
              <Box>
                <Text fontWeight="bold">{t('status')}:</Text>
                <Badge colorScheme={submission.isFinished ? "green" : "orange"}>
                  {submission.isFinished ? t('completed') : t('in_progress')}
                </Badge>
              </Box>
            </SimpleGrid>
          </CardBody>
        </Card>
        
        <Card width="100%" variant="filled" bg="blue.50" boxShadow="md">
          <CardBody>
            <VStack align="start" spacing={3}>
              <Text fontSize="lg" fontWeight="bold">{t('performance_summary')}</Text>
              <Flex width="100%" alignItems="center" gap={4}>
                <Box flex="1">
                  <Progress 
                    value={percentCorrect} 
                    colorScheme={percentCorrect > 70 ? "green" : percentCorrect > 40 ? "orange" : "red"} 
                    size="lg" 
                    borderRadius="md"
                  />
                </Box>
                <Badge 
                  colorScheme={percentCorrect > 70 ? "green" : percentCorrect > 40 ? "orange" : "red"}
                  fontSize="lg"
                  p={2}
                >
                  {correctCount} / {totalQuestions} ({percentCorrect.toFixed(0)}%)
                </Badge>
              </Flex>
            </VStack>
          </CardBody>
        </Card>
        
        {questions.length > 0 && (
          <Box width="100%">
            <Heading size="md" mb={4}>{t('skills_analysis')}</Heading>
            <SkillsAnalysis 
              questions={questions}
              questionsHash={questionsHash}
              submissionsResponses={responses}
            />
          </Box>
        )}
        
        <Heading size="md" mb={4}>{t('questions_and_responses')}</Heading>
        {renderQuestionResponses()}
      </VStack>
    </Box>
  );
};

export default SubmissionDetails; 