import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDoc, doc, setDoc, query, orderBy, limit, startAfter, getDocs, onSnapshot, deleteDoc, updateDoc, serverTimestamp, writeBatch, where, addDoc, collectionGroup } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { getStorage } from 'firebase/storage';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
 apiKey: "AIzaSyCoVR_q7c4EPFQy7S83Q9XByQMEpHWEO34",
  authDomain: "eduscope-cfda5.firebaseapp.com",
  projectId: "eduscope-cfda5",
  storageBucket: "eduscope-cfda5.appspot.com",
  messagingSenderId: "840834241221",
  appId: "1:840834241221:web:07cb0bfdfb5344fad696ac",
  measurementId: "G-6Y5DBLFPKT"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);


export const uploadImageToStorage = async (file, optionId) => {
  const user = auth.currentUser; // Get the currently logged-in user
  if (!user) throw new Error('You must be logged in to upload images.');

  if (!file) throw new Error('No file to upload.');

  // Create an image element
  const img = new Image();
  const reader = new FileReader();

  // Return a promise that resolves when the image is loaded and cropped
  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      img.src = event.target.result;
    };

    img.onload = async () => {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set the canvas size to 300x300
      canvas.width = 300;
      canvas.height = 300;

      // Draw the image onto the canvas, cropping it to 300x300
      ctx.drawImage(img, 0, 0, 300, 300);

      // Convert the canvas to a Blob
      canvas.toBlob(async (blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        // Generate a unique file name using UUID
        const uniqueFileName = `${uuidv4()}.jpg`;

        // Construct a unique storage path
        const storagePath = `options/${user.uid}/${optionId}/${uniqueFileName}`;

        // Create a reference to the file location in Firebase Storage
        const fileRef = ref(storage, storagePath);

        // Upload the blob to Firebase Storage
        await uploadBytes(fileRef, blob);

        // Get the download URL for the uploaded file
        const downloadURL = await getDownloadURL(fileRef);
        resolve(downloadURL);
      }, 'image/jpeg');
    };

    img.onerror = (error) => {
      reject(error);
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  });
};

export const createExportRequest = async (accountId, assessmentId, email) => {
  try {
    const exportsRef = collection(db, 'accounts', accountId, 'exports');
    if(assessmentId){
    await addDoc(exportsRef, {
      assessmentId,
      email,
      createdAt: serverTimestamp(),
    });
    } else {
      await addDoc(exportsRef, {
        email,
        createdAt: serverTimestamp(),
      });
    }
    console.log('Export request created successfully.');
  } catch (error) {
    console.error('Error creating export request:', error);
    throw error;
  }
};


export const checkEmailInInvitations = async (accountId, formId, email) => {
  try {
    const invitationsRef = collection(db, 'accounts', accountId, 'forms', formId, 'invitations');
    const q = query(invitationsRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // If the email is found, return the invitation details
      return querySnapshot.docs[0].data();
    } else {
      // If the email is not found, return null
      return null;
    }
  } catch (error) {
    console.error('Error checking email in invitations:', error);
    throw error;
  }
};

export const requestAdminApproval = async (email, accountId, formId) => {
  try {
    const requestsRef = collection(db, 'accounts', accountId, 'approvalRequests');
    await addDoc(requestsRef, {
      email,
      formId,
      requestedAt: serverTimestamp(),
    });
    console.log('Admin approval request sent successfully.');
  } catch (error) {
    console.error('Error sending admin approval request:', error);
    throw error;
  }
};


export const uploadPdfToStorage = async (file) => {
  const user = auth.currentUser;
  if (!user) throw new Error('You must be logged in to upload files.');

  // Create a unique file name for the storage
  const storagePath = `pdfs/${user.uid}/${new Date().getTime()}-${file.name}`;

  // Create a reference to the Firebase Storage location
  const fileRef = ref(storage, storagePath);

  // Upload the file to Firebase Storage
  await uploadBytes(fileRef, file);

  // Return the download URL
  return getDownloadURL(fileRef);
};

const getUserData = async (uid) => {

  try {
    // Fetch the user document to get the accountId
    const userDocRef = doc(db, `/users/${uid}`);
    const userDocSnap = await getDoc(userDocRef);
    
    if (userDocSnap.exists()) {
      // Assuming accountId is a top-level attribute of the user document
      const userData = userDocSnap.data();
      return userData;
  
    } else {
      console.error("User does not exist in Firestore!");
      return null;
      // Handle the case where user document does not exist
    }
  } catch (error) {
    console.error("Error fetching user document:", error);
    return null;
    // Optionally handle the error, e.g., log the user out
  }
}

const signInWithGoogle = (toast) => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
      .then((result) => {
        toast({
          title: 'Signed in successfully',
          description: `Signed in as ${result.user.email}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }).catch((error) => {
        toast({
          title: 'Error signing in with Google',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
};

const signInWithMicrosoft = (toast) => {
  const provider = new OAuthProvider('microsoft.com');
  signInWithPopup(auth, provider)
      .then((result) => {
        toast({
          title: 'Signed in successfully',
          description: `Signed in as ${result.user.email}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }).catch((error) => {
        toast({
          title: 'Error signing in with Microsoft',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
};

// Function to handle signing in with Email using Firebase Email link authentication method
const signInWithEmailLink = (email, callback) => {
  const actionCodeSettings = {
    url: window.location.href,
    handleCodeInApp: true,
  };
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem('emailForSignIn', email);
      callback(null, true);
    })
    .catch((error) => {
      callback(error, null);
    });
};

// Utility functions to interact with Firestore
const getForm = async (accountId, formId) => {
  accountId = String(accountId).trim();
  const formRef = doc(db, 'accounts', accountId, 'forms', formId);
  let formSnap = await getDoc(formRef);
  let questions = {};
  console.log(formSnap);
  
  if (formSnap.exists()) {
    const questionsRef = collection(db, 'accounts', String(accountId), 'forms', formId, 'questions');
    const questionsSnapshot = await getDocs(questionsRef);
    
    // We use a reduce function to create a hash from the questions
    if(questionsSnapshot.docs.length > 0){
    const questionsHash = questionsSnapshot.docs.reduce(async (accPromise, doc) => {
      const acc = await accPromise; // Since the accumulator is a promise, we await it before using
      const questionData = doc.data();

      // Creating the hash with question IDs as keys
      acc[doc.id] = {
        ...questionData,
      };

      return acc;
    }, Promise.resolve({})); // Initial value is a resolved promise with an empty object
    questions = await questionsHash; // Ensure to await the resulting hash
    }
   
    
   

    return {
      id: formSnap.id,
      ...formSnap.data(),
      questions // Using the hash object instead of an array
    };
  } else {
    throw new Error("No such document!");
  }
};

// Update response for a specific question
const updateFormResponse = async (submissionRef, questionId, response) => {
  console.log('submision ref',submissionRef);
  const submissionId = submissionRef.id;
  const responseRef = doc(submissionRef, 'responses', questionId);
  //get created at
  const responseSnap = await getDoc(responseRef);
  response.updatedAt = serverTimestamp();
  response.createdAt = serverTimestamp();
  response.submissionId = submissionId;
  if (responseSnap.exists()) {
    let createdAt = responseSnap.data().createdAt;
    if(createdAt){
      response.createdAt = createdAt;
    }
  }

  // Set data for response document
  await setDoc(responseRef, response);
};

export const getSubmissionRef = async (accountId, submissionId) => {
  accountId = String(accountId).trim();
  return doc(db, 'accounts', accountId, 'submissions', submissionId);
}

export const updateAssessmentTrackingData = async (accountId, formId, submissionRef, trackingData) => {
  accountId = String(accountId).trim();
  try {
    // Correctly using Firestore methods with db instance
    const submissionDocRef = doc(db, 'accounts', accountId, 'submissions', submissionRef.id);

    await updateDoc(submissionDocRef, {
      trackingData: trackingData,
      lastUpdated: serverTimestamp(),  // Assuming you want to update the lastUpdated field.
    });
    console.log('Successfully updated tracking data');
  } catch (error) {
    console.error('Error updating tracking data:', error);
    throw error;  // Rethrow the error for handling in the caller function
  }
};


// Start the form, set the hasStarted flag and the start time
const startForm = async (data) => {
  let { accountId, formId, assessmentName, userIdentifier, userIdentifierInfo, email, isPreview, sId } = data;
  accountId = String(accountId).trim();
  let key = `submission_${formId}`;
  let tempUUID = uuidv4();
  if(isPreview){
    key = `preview_${formId}_${tempUUID}`;
  }
  let submissionId = sId;
  if(!submissionId){
   submissionId = localStorage.getItem(key);
  }
  if(isPreview && !submissionId){
    submissionId = tempUUID;
  }
  let submissionRef;
  submissionRef = doc(db, 'accounts', accountId, 'submissions', submissionId);

  try {
    await setDoc(submissionRef, {
      hasStarted: true,
      startTime: serverTimestamp(),
      title: assessmentName,
      preview: isPreview,
      formId: formId,
      email: email,
      ...(userIdentifier && { userIdentifier }),
      ...(userIdentifierInfo && { userIdentifierInfo }),
    }, { merge: true });
  } catch (error) {
    console.error("Error creating or updating document:", error);
    // Handle error appropriately, e.g., show a notification to the user
  }

  return (await getDoc(submissionRef)).data();
};


export const getInvitations = (formId, accountId, setInvitedEmails) => {
  accountId = String(accountId).trim();
  if(!accountId || accountId == 'null' || !formId ){ 
    return null;
  }
  const invitationsRef = collection(db, 'accounts', accountId, 'forms', formId, 'invitations');
  const q = query(invitationsRef);
  
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const invitesArray = querySnapshot.docs.map(doc => ({ id: doc.id, email: doc.data().email }));
    setInvitedEmails(invitesArray);
  });

  return unsubscribe; // Return the function to unsubscribe from the listener
};

export const deleteInvitation = async (formId, accountId, invitationId) => {
  accountId = String(accountId).trim();
  await deleteDoc(doc(db, 'accounts', String(accountId), 'forms', formId, 'invitations', invitationId));
};

export const submitInvitations = async (formId, accountId, emailList) => {
  const batch = writeBatch(db);
  
  emailList.forEach(email => {
    const invitationRef = doc(collection(db, 'accounts', accountId , 'forms', formId, 'invitations'));
    batch.set(invitationRef, { email });
  });

  await batch.commit();
};

// Initialize or retrieve the form submission
const initFormSubmission = async (accountId, formId, isPreview, sId) => {
  accountId = String(accountId).trim();
  let submissionId = sId;
  if(!submissionId){
    submissionId = isPreview ? null : localStorage.getItem(`submission_${formId}`);
  }
  let submissionRef;
  let submissionSnap;

  if (submissionId) {
    submissionRef = doc(db, 'accounts', accountId, 'submissions', submissionId);
    submissionSnap = await getDoc(submissionRef);
  } else {
    const submissionsCollectionRef = collection(db, 'accounts', accountId, 'submissions');
    submissionRef = doc(submissionsCollectionRef); // Auto-generates a new document ID
    await setDoc(submissionRef, {
      hasStarted: false,
      responses: {},
      startTime: null
    });
    if (!isPreview) {
      localStorage.setItem(`submission_${formId}`, submissionRef.id);
    }
  }

  return submissionRef;
};

const getSubmissionData = async (submissionRef, accountId, submissionId) => {
  let submissionSnap;
  if (submissionRef) {
    submissionSnap = await getDoc(submissionRef);
  } else if (accountId && submissionId) {
    // Create a document reference using accountId and submissionId
    const submissionDocRef = doc(collection(db, `accounts/${accountId}/submissions`), submissionId);
    submissionSnap = await getDoc(submissionDocRef);
  } else {
    throw new Error("Insufficient information to fetch submission data");
  }
  if (submissionSnap.exists()) {
    const submissionData = submissionSnap.data();
    const responses = await getFormResponses(submissionSnap.ref);
    return {
      ...submissionData,
      responses
    };
  } else {
    throw new Error("Submission not found");
  }
};


export const saveRequestDetails = async (data) => {
  try {
    // Add the new assessment to the '/accounts/:accountId/forms' collection
    const assessmentsCollectionRef = collection(db, `info_request`);
    const assessmentRef = doc(assessmentsCollectionRef); // Auto-generates a new document ID
    await setDoc(assessmentRef, data);
  } catch (error) {
    throw new Error("Error saving request details: ", error);
  }
};



// Save form responses (at submission for example)
const saveFormResponses = async (submissionRef, responses) => {
  // Assuming you want to update the entire responses object at once
  await updateDoc(submissionRef, {
      responses,
      // You might want to add submission complete timestamp
      // completedAt: serverTimestamp(),
  });
};

// Get all responses for the form submission
const getFormResponses = async (submissionRef) => {
  const responsesRef = collection(submissionRef, 'responses');
  const snapshot = await getDocs(responsesRef);
  const responses = snapshot.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {});
  return responses;
};


export const getSubmissionQuery = async (accountId, assessmentId = null, lastVisible = null, pageSize = 50) => {
  if(!accountId){
    return { submissions: [], lastVisible: null };
  }
  const submissionsRef = collection(db, 'accounts', String(accountId), 'submissions');
  
  // Create the initial query
  let q = query(
    submissionsRef,
    orderBy('startTime', 'desc'), // Order by submission start time
    where('hasStarted', '==', true),
    where('preview', '==', false),
    limit(pageSize)
  );

  // Apply the assessmentId filter only if it's provided
  if (assessmentId) {
    q = query(
      submissionsRef,
      orderBy('startTime', 'desc'),
      where('formId', '==', assessmentId),
      where('hasStarted', '==', true),
      limit(pageSize)
    );
  }
  
  // If we have a lastVisible document, paginate from there
  if (lastVisible) {
    q = query(
      submissionsRef,
      ...(assessmentId ? [where('formId', '==', assessmentId)] : []), // Conditionally apply the filter
      orderBy('startTime', 'desc'),
      where('hasStarted', '==', true),
      startAfter(lastVisible),
      limit(pageSize)
    );
  }
  
  try {
    const querySnapshot = await getDocs(q);
    const submissions = [];
    let newLastVisible = null;
    
    // Loop through documents in the querySnapshot
    querySnapshot.forEach(doc => {
      submissions.push({ id: doc.id, ...doc.data() });
    });
    
    // Set the lastVisible document for pagination
    newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    
    // Convert Firebase Timestamp to Date if necessary (for client-side)
    submissions.forEach(submission => {
      if (submission.startTime && submission.startTime.toDate) {
        submission.startTime = submission.startTime.toDate();
      }
    });
    
    return { submissions, lastVisible: newLastVisible };
  } catch (error) {
    console.error('Error fetching submissions: ', error);
    return { submissions: [], lastVisible: null };
  }
};


export const deleteAssessment = async (accountId, assessmentId) => {
  accountId = String(accountId).trim();
  try {
    console.log('delete assessment accountId', accountId);
    const assessmentRef = doc(db, 'accounts', accountId, 'forms', String(assessmentId));
    console.log('assessment Ref', assessmentRef);
    await updateDoc(assessmentRef, { isDeleted: true });
    console.log(`Assessment ${assessmentId} marked as deleted.`);
  } catch (error) {
    console.error('Error deleting assessment:', error);
    throw new Error('Could not delete assessment');
  }
};
export const getResponsesForForm = async (accountId, formId) => {
  try {
    const responsesQuery = query(
      collectionGroup(db, 'responses'),
      where('formId', '==', formId),
      where('accountId', '==', String(accountId))
    );

    const responsesSnapshot = await getDocs(responsesQuery);
    return responsesSnapshot.docs.reduce((acc, doc) => {
      const responseData = doc.data();
      const submissionId = responseData.submissionId;
      const responseId = doc.id;
      if(!acc[submissionId]){
        acc[submissionId] = {};
      }
      acc[submissionId][responseId] = responseData;
      return acc;
    }, {});
  } catch (error) {
    console.error('Error fetching responses for form:', error);
    return {};
  }
};

const getAssessments = async (accountId, assessmentId = null, lastVisible = null, pageSize = 10) => {
  const assessmentsRef = collection(db, 'accounts', String(accountId), 'forms');
  accountId = String(accountId).trim();
  let q;

  if (assessmentId) {
    // For single assessment, get it directly using doc() instead of query
    const docSnap = await getDoc(doc(assessmentsRef, assessmentId));
    if (docSnap.exists()) {
      return {
        assessments: [{ id: docSnap.id, ...docSnap.data() }],
        lastVisible: null
      };
    }
    return { assessments: [], lastVisible: null };
  } else {
    // For multiple assessments, use the existing pagination logic
    q = query(
      assessmentsRef,
      orderBy('createdAt', 'desc'),
      lastVisible ? startAfter(lastVisible) : limit(pageSize)
    );

    const querySnapshot = await getDocs(q);
    const assessments = [];
    let newLastVisible = null;

    querySnapshot.forEach((doc) => {
      assessments.push({ id: doc.id, ...doc.data() });
      newLastVisible = doc;
    });

    return { assessments, lastVisible: newLastVisible };
  }
};


export const fetchAccountData = async (accountId) => {
  try {
    if (!accountId) throw new Error('Account ID is required.');

    const accountDocRef = doc(db, 'accounts', accountId);
    const accountDoc = await getDoc(accountDocRef);

    if (accountDoc.exists()) {
      return accountDoc.data();
    }

    return null;
  } catch (error) {
    console.error('Error fetching account data:', error);
    throw error;
  }
};

/**
 * Starts a trial for the specified account.
 *
 * @param {string} accountId - The ID of the account to update.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */

export const listenToAccountChanges = (accountId, callback) => {
  if (!accountId) {
    throw new Error('Account ID is required.');
  }
  
  const accountDocRef = doc(db, 'accounts', String(accountId));
  
  return onSnapshot(accountDocRef, (docSnapshot) => {
    callback(docSnapshot.data());
  }, (error) => {
    console.error('Error listening to account changes:', error);
  });
};

export const startTrialForAccount = async (accountId) => {
  try {
    const response = await fetch(`https://us-central1-eduscope-cfda5.cloudfunctions.net/startTrialForAccount?accountId=${accountId}`);
    const result = await response.json();

    if (response.ok && result.success) {
      console.log('Trial started successfully:', result);
      return result;
    } else {
      throw new Error(result.message || 'Failed to start trial');
    }
  } catch (error) {
    console.error('Error starting trial:', error);
    throw error;
  }
};

export const getQuestions = async (accountId, assessmentId) => {
  try {
    const questionsRef = collection(db, `/accounts/${accountId}/forms/${assessmentId}/questions`);
    const questionsSnapshot = await getDocs(questionsRef);

    const questions = questionsSnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
      };
    });

    return questions;
  } catch (error) {
    console.error('Error fetching questions:', error);
    return [];
  }
};

export const getSubmissionsByFormAndUser = async (accountId, formIdFilter = null, userIdFilter = null) => {

  let query = collection(db, `/accounts/${accountId}/submissions`);

  // Filter by form ID if provided
  if (formIdFilter) {
    query = query.where('formId', '==', formIdFilter);
  }

  // Filter by user identifier if provided
  if (userIdFilter) {
    query = query.where('userIdentifier', '==', userIdFilter);
  }

  try {
    const querySnapshot = await query.get();
    const submissions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
    return submissions;
  } catch (error) {
    console.error("Error fetching submissions: ", error);
  }
};


export const createBlankAssessment = async (accountId, user) => {
  console.log('create assesment ',accountId );
  try {
    // Construct the new assessment object
    const newAssessment = {
      title: 'New Blank Assessment',
      description: '',
      createdBy: user.uid, // Assuming 'user' object has a 'uid' property
      createdAt: serverTimestamp(), // using serverTimestamp for consistency with other methods
      updatedAt: serverTimestamp(),
      time: 60, // Default time of 60 minutes
      questions: [], // Empty questions array for a blank assessment
      // Add more properties as needed
    };

    // Add the new assessment to the '/accounts/:accountId/forms' collection
    const assessmentsCollectionRef = collection(db, `accounts/${accountId}/forms`);
    const assessmentRef = doc(assessmentsCollectionRef); // Auto-generates a new document ID
    await setDoc(assessmentRef, newAssessment);

    // Return the new assessment ID
    return assessmentRef.id;
  } catch (error) {
    console.error('Error creating blank assessment:', error);
    throw new Error('Failed to create blank assessment');
  }
};

export const updateQuestionIndexes = async (questions) => {
  try {
    const batch = db.batch();
    Object.entries(questions).forEach(([key, question]) => {
      const questionRef = doc(db, 'questions', key);
      batch.update(questionRef, { index: question.index });
    });
    await batch.commit();
  } catch (error) {
    console.error('Error updating question indexes:', error);
  }
};

export const markSubmissionAsFinished = async (accountId, formId, submissionRef) => {
  try {
    // This assumes you have a field named 'isFinished' in your submissions collection
    await updateDoc(submissionRef, {
      isFinished: true,
      finishedAt: serverTimestamp()
  });
    console.log("Submission marked as finished.");
  } catch (error) {
    console.error("Error marking submission as finished:", error);
    throw error; // Rethrow error to handle it in the component
  }
};

const updateForm = async (accountId, formDetails, formId = null) => {
  // Validate form details before updating
  if (!formDetails.title.trim() || formDetails.questions.length === 0) {
    throw new Error('Form must have a title and at least one question.'); // Throw an error if validation fails
  }

  let formRef;
  if (formId) {
    // If id is present, we're updating an existing form
    formRef = doc(db, 'accounts', String(accountId), 'forms', formId);
    if (!formDetails.createdAt) {
      formDetails.createdAt = serverTimestamp();
    }
  } else {
    // If no id, it's a new form
    formDetails.createdAt = serverTimestamp(); // Add a createdAt timestamp
    formRef = doc(collection(db, 'accounts', String(accountId), 'forms'));
  }

  const batch = writeBatch(db); // Use a batch to perform all writes together

  // Clone formDetails to avoid mutating the original object and separate questions
  const newFormDetails = { ...formDetails };
  const questions = newFormDetails.questions || {};
  delete newFormDetails.questions; // Remove questions from the formDetails

  // Set the form details
  batch.set(formRef, newFormDetails);

  // Handle questions, which are stored as a subcollection
  Object.keys(questions).forEach(questionId => {
    const questionRef = doc(formRef, 'questions', questionId); // Use questionId for the question doc
    batch.set(questionRef, questions[questionId]);
  });

  // Handle deleted questions
  const existingQuestions = await getDocs(collection(formRef, 'questions'));
  existingQuestions.forEach(existingQuestion => {
    if (!questions[existingQuestion.id]) {
      batch.delete(existingQuestion.ref);
    }
  });

  // Commit the batch write to Firestore
  await batch.commit();
  
  // Return the existing/new form ID
  return formRef.id;
};

// Add new real-time listener function
export const listenToFormResponses = (accountId, formId, callback) => {
  if (!accountId || !formId) {
    console.warn('AccountId and FormId are required for listening to responses');
    return () => {};
  }

  try {
    const responsesQuery = query(
      collectionGroup(db, 'responses'),
      where('formId', '==', formId),
      where('accountId', '==', String(accountId))
    );

    const unsubscribe = onSnapshot(responsesQuery, 
      (snapshot) => {
        const responses = snapshot.docs.reduce((acc, doc) => {
          const responseData = doc.data();
          const submissionId = responseData.submissionId;
          const responseId = doc.id;
          if(!acc[submissionId]){
            acc[submissionId] = {};
          }
          acc[submissionId][responseId] = responseData;
          return acc;
        }, {});
        
        callback(responses);
      },
      (error) => {
        console.error('Error listening to form responses:', error);
        callback({});
      }
    );

    return unsubscribe;
  } catch (error) {
    console.error('Error setting up responses listener:', error);
    return () => {};
  }
};

export const listenToSubmissions = (accountId, formId, callback) => {
  if (!accountId) {
    console.warn('AccountId is required for listening to submissions');
    return () => {};
  }

  try {
    // Create base query
    let submissionsRef = collection(db, 'accounts', accountId, 'submissions');
    
    // Add formId filter if provided
    if (formId) {
      submissionsRef = query(submissionsRef, where('formId', '==', formId));
    }

    // Add ordering and limit
    submissionsRef = query(
      submissionsRef, 
      orderBy('startTime', 'desc'),
      limit(50)
    );

    const unsubscribe = onSnapshot(submissionsRef,
      (snapshot) => {
        const submissions = [];
        let lastVisible = null;

        snapshot.forEach((doc) => {
          submissions.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        if (submissions.length > 0) {
          lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }

        callback({ submissions, lastVisible });
      },
      (error) => {
        console.error('Error listening to submissions:', error);
        callback({ submissions: [], lastVisible: null });
      }
    );

    return unsubscribe;
  } catch (error) {
    console.error('Error setting up submissions listener:', error);
    return () => {};
  }
};

// Function to fetch more submissions (pagination)
export const fetchMoreSubmissions = async (accountId, formId, lastVisible) => {
  if (!lastVisible) return { submissions: [], lastVisible: null };

  try {
    // Create base query
    let submissionsRef = collection(db, 'accounts', accountId, 'submissions');
    
    // Build query with pagination
    const paginatedQuery = query(
      submissionsRef,
      formId ? where('formId', '==', formId) : undefined,
      orderBy('startTime', 'desc'),
      startAfter(lastVisible),
      limit(50)
    );

    const snapshot = await getDocs(paginatedQuery);
    const submissions = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    let lastVisible = null;

    if (submissions.length > 0) {
      lastVisible = snapshot.docs[snapshot.docs.length - 1];
    }

    return { submissions, lastVisible };
  } catch (error) {
    console.error('Error fetching more submissions:', error);
    return { submissions: [], lastVisible: null };
  }
};

export const createExportSubmissionPDF = async (accountId, submissionId, email) => {
  try {
    const submissionPdfExportsRef = collection(db, 'accounts', accountId, 'submissionPdfExports');
    await addDoc(submissionPdfExportsRef, {
      submissionId,
      email,
      createdAt: serverTimestamp(),
    });
    console.log('Export PDF request created successfully.');
  } catch (error) {
    console.error('Error creating PDF export request:', error);
    throw error;
  }
};

export {auth, getUserData, signInWithGoogle, signInWithMicrosoft, signInWithEmailLink, getForm, getAssessments,  updateForm,  getSubmissionData, initFormSubmission, updateFormResponse, startForm, saveFormResponses  };

