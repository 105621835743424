import React, { useState, useEffect } from 'react';
import {
  Box, Button, Table, Thead, Tbody, Tr, Th, Td, Text, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, useDisclosure, useToast
} from '@chakra-ui/react';
import { getAssessments, getSubmissionQuery, getQuestions, getResponsesForForm, createExportRequest, listenToFormResponses, listenToSubmissions, fetchMoreSubmissions } from '../utils/firebase.utils';
import { useTranslation } from 'react-i18next';
import { DownloadIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { collection, query, where, orderBy, startAfter, limit, getDocs } from 'firebase/firestore';
import { db } from '../utils/firebase.utils';
import SkillsAnalysis from './components/SkillsAnalysis';
import { Link } from 'react-router-dom';

const AssessmentData = ({ accountId, assessmentId, userEmail }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [assessments, setAssessments] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsHash, setQuestionsHash] = useState({});
  const [lastVisible, setLastVisible] = useState(null);
  const [submissionsResponses, setSubmissionsResponses] = useState({});
  const [email, setEmail] = useState(userEmail);

  useEffect(() => {
    const fetchAssessments = async () => {
      if(accountId){
        const { assessments } = await getAssessments(accountId, assessmentId);
        const assessmentsHash = assessments.reduce((acc, assessment) => {
          acc[assessment.id] = assessment;
          return acc;
        }, {});
        console.log('assessmentsHash', assessmentsHash);
        setAssessments(assessmentsHash);
      }
    };

    fetchAssessments();
  }, [accountId]);

  useEffect(() => {
    let unsubscribeSubmissions;
    
    if (accountId) {
      unsubscribeSubmissions = listenToSubmissions(
        accountId,
        assessmentId,
        ({ submissions: newSubmissions, lastVisible: newLastVisible }) => {
          setSubmissions(newSubmissions);
          setLastVisible(newLastVisible);
        }
      );
    }

    return () => {
      if (unsubscribeSubmissions) {
        unsubscribeSubmissions();
      }
    };
  }, [accountId, assessmentId]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const questions = await getQuestions(accountId, assessmentId);
      const questionsHash = {};
      questions.forEach((question) => {
        questionsHash[question.id] = questionsHash[question.id] || {};
        Object.entries(question.options).forEach(([key, option]) => {
          if (option.value > 0) {
            questionsHash[question.id][key] = option.value;
          }
        });
      });
      setQuestions(questions);
      setQuestionsHash(questionsHash);
    };

    let unsubscribeResponses;
    if (assessmentId && accountId) {
      fetchQuestions();
      
      unsubscribeResponses = listenToFormResponses(
        accountId, 
        assessmentId, 
        (responses) => {
          setSubmissionsResponses(responses);
        }
      );
    }

    return () => {
      if (unsubscribeResponses) {
        unsubscribeResponses();
      }
    };
  }, [assessmentId, accountId]);

  const handleLoadMore = async () => {
    if (!lastVisible) return;

    const { submissions: newSubmissions, lastVisible: newLastVisible } = 
      await fetchMoreSubmissions(accountId, assessmentId, lastVisible);

    setSubmissions(prev => [...prev, ...newSubmissions]);
    setLastVisible(newLastVisible);
  };

  const handleExport = async () => {
    try {
      await createExportRequest(accountId, assessmentId, email);
      toast({
        title: t('export_requested'),
        description: t('email_with_link'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: t('error'),
        description: t('export_error'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderSubmissionsTable = () => (
    <Box 
      overflowX="auto" 
      maxHeight="600px" 
      overflowY="auto" 
      position="relative"
    >
      <Table variant="simple" size="sm">
        <Thead 
          position="sticky" 
          top={0} 
          backgroundColor="white" 
          zIndex={1}
          boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
        >
          <Tr>
            {!assessmentId && (
              <Th position="sticky" left={0} backgroundColor="white" zIndex={2}>
                {t('tableHeaders.assessment')}
              </Th>
            )}
            <Th position="sticky" left={!assessmentId ? '150px' : 0} backgroundColor="white" zIndex={2}>
              {t('tableHeaders.userIdentifier')}
            </Th>
            <Th>{t('tableHeaders.score')}</Th>
            <Th 
              width="150px" 
              minWidth="150px" 
              sx={{ width: '150px !important', minWidth: '150px !important' }}
            >
              {t('tableHeaders.startTime')}
            </Th>
            <Th>{t('tableHeaders.isFinished')}</Th>
            {Object.entries(questions).map(([key, question]) => (
              <Th key={key}>
                <Tooltip label={question.name} aria-label={`Tooltip for ${question.title}`}>
                  <span>{parseInt(key) + 1}</span>
                </Tooltip>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
        {submissions
            .filter(submission => submission.title)
            .map(submission => (
            <Tr key={submission.id} height="40px">
              {!assessmentId && (
                <Td position="sticky" left={0} backgroundColor="white">
                  {submission.title}
                </Td>
              )}
              <Td position="sticky" left={!assessmentId ? '150px' : 0} backgroundColor="white">
                <Link to={`/admin/submission/${submission.formId}/${submission.id}`} display="flex" alignItems="center">
                  {submission.userIdentifier || submission.email}
                  <ExternalLinkIcon ml={2} />
                </Link>
              </Td>
              <Td>{submission.score || 0}{(assessments[submission.formId] || {}).totalPoints > 0 ? `/${assessments[submission.formId].totalPoints}` : '' }</Td>
              <Td 
                width="150px" 
                minWidth="150px"
                sx={{ width: '150px !important', minWidth: '150px !important' }}
              >
                {submission.startTime?.toDate().toLocaleString('en-US', { 
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                })}
              </Td>
              <Td>{submission.isFinished ? t('tableHeaders.yes') : t('tableHeaders.no')}</Td>
              {questions.map((question) => {
                const response = (submissionsResponses[submission.id] || {})[question.id];
                const responseColor = response && questionsHash[question.id][response.id] ? 'green' : 'red';
                const responseSymbol = response && questionsHash[question.id][response.id] ? '✓' : '✗';
                const tooltipText = response ? response.name : t('tableHeaders.noResponse');
                return (
                  <Td key={question.id}>
                    <Tooltip label={tooltipText} aria-label="Response Tooltip">
                      <Text color={responseColor}>
                        {responseSymbol}
                      </Text>
                    </Tooltip>
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );

  return (
    <Box>
        <Box display="flex" justifyContent="flex-end" mt="10px" mb="5px">
          <Button size="sm" onClick={onOpen}>
            <DownloadIcon />
            {t('export_data')}
          </Button>
        </Box>

      {assessmentId && (
        <SkillsAnalysis 
          questions={questions}
          questionsHash={questionsHash}
          submissionsResponses={submissionsResponses}
        />
      )}

      {submissions.length > 0 ? renderSubmissionsTable() : <p>{t('messages.noSubmissions')}</p>}
      {lastVisible && submissions.length >= 50 && (
        <Button mt={4} onClick={handleLoadMore}>
         {t('Load More')}
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('export_data')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>{t('email')}</FormLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>{t('cancel')}</Button>
            <Button colorScheme="blue" onClick={handleExport} ml={3}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AssessmentData;